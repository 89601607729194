import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import {
    FullWidthDatePicker,
    FullWidthTextField,
    Condition,
    True,
    False,
    FullWidthSearchSelectField,
    FullWidthCheckBoxField,
    FullWidthRichTextField,
    WhenFieldChanges,
} from '../../../../../forms';
import { requiredValidator, conditionalRequired } from '../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { DocumentName } from '../../../../../codes/document-name';
import { State } from '../../../../../redux/root-reducer';
import { AuthState } from '../../../../../auth/auth-reducer';

type Props = StyledComponent & {
    currentUser: AuthState;
};

const AdditionalInformationRequiredRecordDetailView: React.FunctionComponent<Props> = ({
    classes,
    currentUser,
}: Props) => {
    return (
        <React.Fragment>
            <Condition when="dateRequested" hasValue>
                <True>
                    <Details currentUser={currentUser} />
                </True>
                <False>
                    <Details currentUser={currentUser} disabled />
                </False>
            </Condition>
        </React.Fragment>
    );
};

interface DetailsProps {
    currentUser: AuthState;
    disabled?: boolean;
}
const Details: React.FunctionComponent<DetailsProps> = ({
    currentUser,
    disabled,
}: DetailsProps) => {
    return (
        <Grid item container spacing={5}>
            <Grid item xs={12} lg={12}>
                <FullWidthTextField
                    id="detailsOfInformationRequested"
                    name="detailsOfInformationRequested"
                    multiline
                    validate={requiredValidator}
                    autoFocus
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <FullWidthTextField
                    id="reasonForRequest"
                    name="reasonForRequest"
                    multiline
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6} lg={6}>
                <FullWidthDatePicker
                    id="dateRequested"
                    name="dateRequested"
                    validate={requiredValidator}
                    disableFuture
                />
            </Grid>
            <Grid item xs={6} lg={6}>
                <FullWidthDatePicker
                    id="dateReceived"
                    name="dateReceived"
                    disabled={disabled}
                    disableFuture
                />
                <WhenFieldChanges field="dateReceived" set="dateOfSummary" to={() => new Date()} />
                <WhenFieldChanges
                    field="dateReceived"
                    set="assessedBy"
                    to={() => {
                        return `${currentUser.name} ${currentUser.userId}`;
                    }}
                />
            </Grid>
            <Grid item xs={6} lg={6}>
                <Condition when="dateReceived" hasValue>
                    <True>
                        <FullWidthSearchSelectField
                            id="documentName"
                            name="documentName"
                            validate={requiredValidator}
                            options={DocumentName}
                        />
                    </True>
                    <False>
                        <FullWidthSearchSelectField
                            id="documentName"
                            name="documentName"
                            validate={requiredValidator}
                            options={DocumentName}
                            maxMenuHeight="8em"
                        />
                    </False>
                </Condition>
            </Grid>
            <Condition when="documentName" is="Other">
                <True>
                    <Grid item xs={6} lg={6}>
                        <FullWidthTextField
                            name="documentDescription"
                            id="documentDescription"
                            validate={conditionalRequired(
                                values => values.documentName === 'Other'
                            )}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when="dateReceived" hasValue>
                <True>
                    <Grid item xs={6} lg={6}>
                        <FullWidthTextField
                            id="documentID"
                            name="documentID"
                            validate={requiredValidator}
                            // eslint-disable-next-line
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <FullWidthDatePicker
                            id="dateOfReport"
                            name="dateOfReport"
                            validate={requiredValidator}
                            disableFuture
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <FullWidthDatePicker
                            id="dateOfSummary"
                            name="dateOfSummary"
                            validate={requiredValidator}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <FullWidthRichTextField
                            id="summary"
                            name="summary"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <FullWidthCheckBoxField
                            name="sendToUnderwriter"
                            label="Refer to Underwriter"
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when="dateReceived" hasValue>
                <False>
                    <Grid container style={{ height: 80 }}></Grid>
                </False>
            </Condition>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    currentUser: state.auth as AuthState,
});

export default connect(
    mapStateToProps,
    {}
)(withStyles(styles)(AdditionalInformationRequiredRecordDetailView));
